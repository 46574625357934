export const environment = {
  production: true,
  baseUrl: 'https://api.codingninjas.in/api/',
  baseNodeUrl: 'https://az-ninjasio-staging.codingninjas.com/',
  hmr: false,
  codezenUrl: 'https://staging.codingninjas.in/app/',
  mentorsUrl: 'https://test-ninjas-mentors.firebaseapp.com/',
  domainRoot: 'codingninjas.com',
  googleClientId:
    '727224546303-n3qbta0ihqo93h1sccobhglq1vjoqai6.apps.googleusercontent.com',
  beLoginURL:
    'https://admin.codingninjas.com/users/auth/google_oauth2?from_fe=true',
};
