import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  faFacebook,
  faFacebookF,
  faGoogle,
} from '@fortawesome/free-brands-svg-icons';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { NetworkingModule } from '@codingninjas/networking';
import { FuseModule } from '../@fuse/fuse.module';
import { fuseConfig } from './core/fuse-config';
import { MentorsAppComponent } from './core/components/mentors-app/mentors-app.component';
import { MentorGuard } from './core/guards/mentor.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { NotificationModule } from '@codingninjas/notification';
import { HomeRedirectGuard } from './core/guards/home-redirect.guard';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
const brandIcons = [faFacebook, faFacebookF, faGoogle];
@NgModule({
  declarations: [AppComponent, MentorsAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {}),
    FuseModule.forRoot(fuseConfig),
    FontAwesomeModule,
    CoreModule,
    NetworkingModule,
    NotificationModule,
    MatDialogModule,
  ],
  providers: [
    MentorGuard,
    AdminGuard,
    HomeRedirectGuard,
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
  exports: [BrowserModule, BrowserAnimationsModule],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...brandIcons);
  }
}
