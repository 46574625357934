import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { RxSocket } from './rx-socket';
import Socket = SocketIOClient.Socket;

@Injectable({
  providedIn: 'root',
})
export class SocketsService {
  socketPool: Map<string, Socket> = new Map<string, Socket>();

  constructor() {}

  getSocket(host: string, queryString?: string): RxSocket {
    const key = host + (queryString ? queryString : '');
    if (!this.socketPool.has(key)) {
      this.socketPool.set(key, socketIo.connect(host, { query: queryString,
        path: '/ninjasIo/socket.io'
      }));
    }
    return new RxSocket(this.socketPool.get(key));
  }
}

export interface SocketOptions {
  forceNew: boolean;
}
