import { createAction, props } from '@ngrx/store';

export const setCareerCampProductPresence = createAction(
  '[Courses] Set career camp product presence',
  props<{ careerCampProductPresent: boolean }>()
);

export const setIitgCourse = createAction(
  '[courses] Set iitg course present',
  props<{ iitgCoursePresent: boolean }>()
);
export const setCCJobGuaranteeProductPresence = createAction(
  '[Courses] Set CC job guarantee product presence',
  props<{ ccJobGuaranteeProductPresent: boolean }>()
);

export const setCareerCampWebProductPresence = createAction(
  '[Courses] Set career camp web product presence',
  props<{ careerCampWebProductPresent: boolean }>()
);

export const setOnlyCareerCampDaProductPresence = createAction(
  '[Courses] Set only career camp DA product presence',
  props<{ onlyCareerCampDaProductPresent: boolean }>()
);

export const setPrepaidProductPresence = createAction(
  '[Courses] Set prepaid product presence',
  props<{ prepaidProductPresent: boolean }>()
);

export const setActiveProductCategory = createAction(
  '[Courses] Set active product category',
  props<{ activeProductCategory: string }>()
);

export const setSkillSetPresence = createAction(
  '[Courses] Set skill set presence',
  props<{ skillSetPresent: boolean }>()
);

export const setReferralConnectGuidelinesAccepted = createAction(
  '[Courses] Set referral connect guidelines accepted',
  props<{ referralConnectGuidelinesAccepted: boolean }>()
);

export const setMockSessionPresence = createAction(
  '[Courses] Set mock session presence',
  props<{ mockSessionsPresent: boolean }>()
);

export const setRecentBookingsCount = createAction(
  '[Courses] Set recent bookings count',
  props<{ recentBookingsCount: number }>()
);

export const setDailyEventCTA = createAction(
  '[Courses] Set daily event CTA',
  props<{ dailyEventCTA: string }>()
);
