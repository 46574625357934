import { Inject, Injectable } from '@angular/core';
import {
  AnalyticsEvent,
  GA4AnalyticsEvent,
} from '../models/analytics-event.model';
import {
  Authenticated,
  AuthenticationError,
  AuthQuery,
  AuthService,
  AuthState,
  AuthUser,
  Logout,
} from '@codingninjas/auth';
import { select, Store } from '@ngrx/store';
import { AnalyticsService } from './analytics.service';
import {
  ApiClient,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import { UserTrackingResponse } from '../models/user-tracking.response';
import { setChangeUserNameNudge } from 'libs/auth/src/lib/+state/user-actions/user-actions.actions';
import {
  setActiveProductCategory,
  setCareerCampProductPresence,
  setCareerCampWebProductPresence,
  setDailyEventCTA,
  setCCJobGuaranteeProductPresence,
  setMockSessionPresence,
  setOnlyCareerCampDaProductPresence,
  setPrepaidProductPresence,
  setRecentBookingsCount,
  setReferralConnectGuidelinesAccepted,
  setSkillSetPresence,
  setIitgCourse,
} from '../../../../auth/src/lib/+state/course/courses.actions';
import { isNotNullOrUndefined } from '../utils/blank-check.util';

@Injectable({
  providedIn: 'root',
})
export class AuthAnalyticsService {
  provider: string;
  userTrackingTypeDone = false;

  constructor(
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private apiClient: ApiClient,
    private authStore: Store<AuthState>,
    private store: Store,
    @Inject('env') private environment
  ) {
    this.authStore.pipe(select(AuthQuery.getProvider)).subscribe((provider) => {
      this.provider = provider;
    });

    this.authStore
      .pipe(select(AuthQuery.getUser))
      .subscribe((user: AuthUser) => {
        this.defineUserTrackingDetail(user);
      });

    this.authStore
      .pipe(select(AuthQuery.getIfNewUser))
      .subscribe((isNewUser) => {
        if (isNewUser) {
          this.sendEvent({
            name: 'CompleteRegistration',
            category: 'User Auth',
            action: 'Register',
            label: 'Registration Completed',
          });
        }
      });

    this.authService.authActions$.subscribe((action) => {
      if (action) {
        if (action instanceof Logout) {
          if (this.environment?.app === 'publicsection') {
            this.analyticsService.webengageLogout();
            this.analyticsService.posthogLogout();
          }
          this.setUser(null, {
            name: 'User Logout',
            category: 'User Auth',
            action: 'Logout',
            label: 'Logout Success',
          });
        } else if (action instanceof Authenticated) {
          this.setUser(
            action.payload.user,
            {
              name: 'User Login',
              category: 'User Auth',
              action: 'Login',
              label: 'Login Success',
            },
            action.payload?.new_user || action.payload?.first_time_login
          );
        } else if (action instanceof AuthenticationError) {
          this.setUser(null, {
            name: 'User Login',
            category: 'User Auth',
            action: 'Login',
            label: 'Login Failure',
          });
        }
      }
    });
  }

  setUser(user: AuthUser, eventData: AnalyticsEvent, newUser: boolean = false) {
    if (eventData.name == null) {
      return;
    }

    const currentPageUrl = window.location.href;

    this.sendEvent({
      name: eventData.name,
      category: eventData.category,
      action: eventData.action,
      label: eventData.label,
      data: {
        userEmail: user?.email || '',
        userName: user?.name || '',
        userId: user?.id || '',
        userPhoneNumber: user?.phone_number || '',
        userBranch: user?.branch || '',
        userGraduationYear: user?.graduation_year || '',
        userGraduated: user?.graduated || false,
        userCollege: user?.college || '',
        userReferrerId: user?.referrer_id || '',
        userIsEmployee: user?.is_employee || false,
        userGender: user?.gender || '',
        userIsTA: user?.is_ta || false,
        signup: newUser ? 'yes' : 'no',
        pageURL: currentPageUrl,
      },
    });
  }

  sendEvent(eventData: AnalyticsEvent) {
    this.analyticsService.sendEvent(eventData);
  }

  sendEcommerceEvent(eventData: AnalyticsEvent) {
    this.analyticsService.sendEcommerceEvent(
      eventData.name,
      eventData.action,
      eventData.label,
      eventData.ecommerce
    );
  }

  sendGA4EcommerceEvent(eventData: GA4AnalyticsEvent) {
    this.analyticsService.sendGA4EcommerceEvent(
      eventData.name,
      eventData.ecommerce
    );
  }

  setCustomVariables(customVariablesMap: {}) {
    this.analyticsService.setCustomVariables(customVariablesMap);
  }

  defineUserTrackingDetail(user: AuthUser) {
    if (user == null) {
      this.sendEvent({
        name: 'Track set',
        data: {
          trackType: 'none',
        },
      });
      return;
    }

    if (this.userTrackingTypeDone) {
      return;
    }
    this.userTrackingTypeDone = true;
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V2.USER_TRACKING_TYPE
    ).build();
    this.apiClient
      .enqueue<UserTrackingResponse>(call)
      .subscribe((userTrackingResource: Resource<UserTrackingResponse>) => {
        if (!userTrackingResource.isSuccessful()) {
          return;
        }
        const userTrackingResponse = userTrackingResource.data;
        const userTrackingDetails = userTrackingResponse.user_tracking_details;

        if (
          isNotNullOrUndefined(userTrackingDetails.career_camp_product_exists)
        ) {
          this.store.dispatch(
            setCareerCampProductPresence({
              careerCampProductPresent:
                userTrackingDetails.career_camp_product_exists,
            })
          );
          this.store.dispatch(
            setIitgCourse({
              iitgCoursePresent: userTrackingDetails.iitg_course,
            })
          );
        }
        if (
          isNotNullOrUndefined(userTrackingDetails.job_guarantee_product_exists)
        ) {
          this.store.dispatch(
            setCCJobGuaranteeProductPresence({
              ccJobGuaranteeProductPresent:
                userTrackingDetails.job_guarantee_product_exists,
            })
          );
        }
        if (
          isNotNullOrUndefined(
            userTrackingDetails.career_camp_web_product_exists
          )
        ) {
          this.store.dispatch(
            setCareerCampWebProductPresence({
              careerCampWebProductPresent:
                userTrackingDetails.career_camp_web_product_exists,
            })
          );
        }
        if (
          isNotNullOrUndefined(
            userTrackingDetails.only_career_camp_da_product_exists
          )
        ) {
          this.store.dispatch(
            setOnlyCareerCampDaProductPresence({
              onlyCareerCampDaProductPresent:
                userTrackingDetails.only_career_camp_da_product_exists,
            })
          );
        }
        if (isNotNullOrUndefined(userTrackingDetails.prepaid_product_exists)) {
          this.store.dispatch(
            setPrepaidProductPresence({
              prepaidProductPresent: userTrackingDetails.prepaid_product_exists,
            })
          );
        }
        if (isNotNullOrUndefined(userTrackingDetails.has_skill_sets)) {
          this.store.dispatch(
            setSkillSetPresence({
              skillSetPresent: userTrackingDetails.has_skill_sets,
            })
          );
        }
        if (
          isNotNullOrUndefined(
            userTrackingDetails.referral_connect_guidelines_accepted
          )
        ) {
          this.store.dispatch(
            setReferralConnectGuidelinesAccepted({
              referralConnectGuidelinesAccepted:
                userTrackingDetails.referral_connect_guidelines_accepted,
            })
          );
        }
        if (isNotNullOrUndefined(userTrackingDetails.has_mock_sessions)) {
          this.store.dispatch(
            setMockSessionPresence({
              mockSessionsPresent: userTrackingDetails.has_mock_sessions,
            })
          );
        }
        if (isNotNullOrUndefined(userTrackingDetails.recent_bookings_count)) {
          this.store.dispatch(
            setRecentBookingsCount({
              recentBookingsCount: userTrackingDetails.recent_bookings_count,
            })
          );
        }
        if (userTrackingDetails.user_active_course_product_category) {
          this.store.dispatch(
            setActiveProductCategory({
              activeProductCategory:
                userTrackingDetails.user_active_course_product_category,
            })
          );
        }
        const codestudioUserTrackingDetails =
          userTrackingResponse.codestudio_tacking_details;

        const dailyEventCTA = userTrackingResponse.daily_event_cta;
        this.store.dispatch(
          setDailyEventCTA({
            dailyEventCTA,
          })
        );
        const courseModuleData = {};
        for (
          let i = 0;
          i < userTrackingResponse.course_module_names.length;
          i++
        ) {
          const item = userTrackingResponse.course_module_names[i];
          const key = 'activeCourseModule' + (i + 1);
          courseModuleData[key] = item;
        }

        if (
          window.location.toString().indexOf('classroom.codingninjas.com') !==
          -1
        ) {
          this.sendEvent({
            name: 'Track set',
            data: {
              userTrackType: userTrackingResponse.track_type,
            },
          });
        }

        let userType = userTrackingDetails.user_type;
        if (this.provider === 'login_as') {
          userType = 'Loginas';
        }

        if (userTrackingDetails?.user_username_change_nudge) {
          this.store.dispatch(setChangeUserNameNudge());
        }

        const data = {
          userEmail: user.email,
          userName: user.name || '',
          userId: user.id,
          userPhoneNumber: user.international_phone_number || '',
          userBranch: user.branch || '',
          userGraduationYear: user.graduation_year || '',
          userGraduated: user.graduated || false,
          userCollege: user.college || '',
          userReferrerId: user.referrer_id || '',
          userGender: user.gender || '',
          userType: userType || '',
          userActiveCourseProductCount:
            userTrackingDetails.user_active_product_count || 0,
          userActiveCourseProductName:
            userTrackingDetails.user_active_product_name || '',
          userActiveCourseProductId:
            userTrackingDetails.user_active_course_product_id || '',
          userActiveCourseProductStartDate:
            userTrackingDetails.user_active_course_product_start_date || '',
          userActiveCourseProductEndDate:
            userTrackingDetails.user_active_course_product_end_date || '',
          userActiveCourseProductShiftedEndDate:
            userTrackingDetails.user_active_course_product_shifted_end_date ||
            '',
          userActiveCourseProductCategory:
            userTrackingDetails.user_active_course_product_category || '',
          userActiveCourseProductClassroomVersion:
            userTrackingDetails.user_active_course_product_version || '',
          leaderboardLeague: userTrackingDetails.leaderboard_league || '',
          userTrackType: userTrackingResponse.track_type || '',
          latestNPSRating: userTrackingDetails.latest_nps_rating || '',
          userAwardsKey: userTrackingDetails.user_awards_key || '',
          userAwardsCount: userTrackingDetails.user_awards_count || 0,
          userCollegeId: codestudioUserTrackingDetails?.college_id,
        };

        const codestudioUserData = {
          exp: codestudioUserTrackingDetails?.exp,
          current_goal: codestudioUserTrackingDetails?.goal,
          enrolled_gp: codestudioUserTrackingDetails?.enrolled_gp,
          exp_level: codestudioUserTrackingDetails?.current_level,
          rating: codestudioUserTrackingDetails?.contest_rating,
          college_id: codestudioUserTrackingDetails?.college_id,
          registration_utm_source:
            codestudioUserTrackingDetails?.registration_utm_source,
          registration_utm_medium:
            codestudioUserTrackingDetails?.registration_utm_medium,
          registration_utm_campaign:
            codestudioUserTrackingDetails?.registration_utm_campaign,
        };

        const mergedData = {
          ...data,
          ...courseModuleData,
          ...codestudioUserData,
        };

        if (this.environment?.app === 'publicsection') {
          this.analyticsService.sendPSUserDefined({
            name: 'User Defined',
            ...mergedData,
          });
        } else {
          this.sendEvent({
            name: 'User Defined',
            data: mergedData,
          });
        }
      });
  }
}
