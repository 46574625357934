import { createReducer, on } from '@ngrx/store';
import {
  setActiveProductCategory,
  setCareerCampProductPresence,
  setMockSessionPresence,
  setPrepaidProductPresence,
  setRecentBookingsCount,
  setSkillSetPresence,
  setReferralConnectGuidelinesAccepted,
  setCareerCampWebProductPresence,
  setOnlyCareerCampDaProductPresence,
  setDailyEventCTA,
  setCCJobGuaranteeProductPresence,
  setIitgCourse,
} from './courses.actions';

export const coursesFeatureKey = 'courses';

export interface CoursesState {
  careerCampProductPresent: boolean;
  ccJobGuaranteeProductPresent: boolean;
  careerCampWebProductPresent: boolean;
  onlyCareerCampDaProductPresent: boolean;
  prepaidProductPresent: boolean;
  activeProductCategory: string;
  skillSetPresent: boolean;
  mockSessionsPresent: boolean;
  recentBookingsCount: number;
  referralConnectGuidelinesAccepted: boolean;
  dailyEventCTA: string;
  iitgCoursePresent: boolean;
}

export const initialState: CoursesState = {
  careerCampProductPresent: false,
  ccJobGuaranteeProductPresent: false,
  careerCampWebProductPresent: false,
  onlyCareerCampDaProductPresent: false,
  prepaidProductPresent: false,
  activeProductCategory: '',
  skillSetPresent: false,
  mockSessionsPresent: false,
  recentBookingsCount: 0,
  referralConnectGuidelinesAccepted: false,
  dailyEventCTA: null,
  iitgCoursePresent: false,
};

export const coursesReducer = createReducer(
  initialState,
  on(setCareerCampProductPresence, (state, { careerCampProductPresent }) => ({
    ...state,
    careerCampProductPresent,
  })),
  on(
    setCCJobGuaranteeProductPresence,
    (state, { ccJobGuaranteeProductPresent }) => ({
      ...state,
      ccJobGuaranteeProductPresent,
    })
  ),
  on(
    setCareerCampWebProductPresence,
    (state, { careerCampWebProductPresent }) => ({
      ...state,
      careerCampWebProductPresent,
    })
  ),
  on(
    setOnlyCareerCampDaProductPresence,
    (state, { onlyCareerCampDaProductPresent }) => ({
      ...state,
      onlyCareerCampDaProductPresent,
    })
  ),
  on(setPrepaidProductPresence, (state, { prepaidProductPresent }) => ({
    ...state,
    prepaidProductPresent,
  })),
  on(setActiveProductCategory, (state, { activeProductCategory }) => ({
    ...state,
    activeProductCategory,
  })),
  on(setSkillSetPresence, (state, { skillSetPresent }) => ({
    ...state,
    skillSetPresent,
  })),
  on(
    setReferralConnectGuidelinesAccepted,
    (state, { referralConnectGuidelinesAccepted }) => ({
      ...state,
      referralConnectGuidelinesAccepted,
    })
  ),
  on(setMockSessionPresence, (state, { mockSessionsPresent }) => ({
    ...state,
    mockSessionsPresent,
  })),
  on(setRecentBookingsCount, (state, { recentBookingsCount }) => ({
    ...state,
    recentBookingsCount,
  })),
  on(setDailyEventCTA, (state, { dailyEventCTA }) => ({
    ...state,
    dailyEventCTA,
  })),
  on(setIitgCourse, (state, { iitgCoursePresent }) => ({
    ...state,
    iitgCoursePresent,
  }))
);
