import { Injectable, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  AnalyticsEvent,
  EcommerceAction,
} from '../models/analytics-event.model';
import { NavigationEnd, Router } from '@angular/router';
import { WebengageService } from '@codingninjas/ninjas-utils/services/webengage-init.service';
import { ClarityService } from '@codingninjas/ninjas-utils/services/clarity-init.service';
import { PosthogService } from '@codingninjas/ninjas-utils/services/posthog-init.service';
import { AlgoliaService } from '@codingninjas/ninjas-utils/services/algolia-init.service';
import { Store } from '@ngrx/store';
import { AuthState } from '@codingninjas/auth';
import { BrowserService } from '@codingninjas/ninjas-utils/services/browser.service';
import { sampleBasedOnMarketingToken } from '@codingninjas/ninjas-utils/utils/sampling.util';

declare let dataLayer: any;
declare let gtag: any;

/**
 *
 * Wrap all the functions in this service inside
 *
 * if (isPlatformBrowser(this.platformId)) {}
 *
 * as this is required for apps with SSR and
 * this is a common service
 *
 */
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private displayMode: string;
  userLogin;
  verifiedEmail;
  naukriFlowUser = 0;
  userSignup = 0;
  registrationUtmSource = null;
  registrationUtmMedium = null;
  registrationUtmCampaign = null;
  sourcePageUrl = null;
  sessionStartPageUrl = null;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private webengageService: WebengageService,
    private clarityService: ClarityService,
    @Inject('env') private environment,
    private router: Router,
    private posthogService: PosthogService,
    private algoliaService: AlgoliaService,
    private authStore: Store<AuthState>,
    private ngZone: NgZone,
    private browserService: BrowserService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.setDisplayMode();
      this.listenToAuthStore();
      if ((window as any).pushEventsToQueue) {
        window.addEventListener(
          'gtm_script_loaded',
          () => {
            while ((window as any).gtmEventQueue.length > 0) {
              this.pushEventToDatalayer((window as any).gtmEventQueue[0]);
              (window as any).gtmEventQueue.shift();
            }
            if (!(window as any).gtmEventQueue.length) {
              delete (window as any).gtmEventQueue;
            }
            while ((window as any).gtmEcommerceQueue.length > 0) {
              dataLayer.push((window as any).gtmEcommerceQueue[0]);
              (window as any).gtmEcommerceQueue.shift();
            }
            if (!(window as any).gtmEcommerceQueue.length) {
              delete (window as any).gtmEcommerceQueue;
            }
          },
          { once: true }
        );
      }
      if (this.environment?.app === 'publicsection') {
        this.posthogService.init({});
        const marketing_token = this.browserService.getMarketingToken();
        if (
          !this.isLowEndDevice() ||
          (this.isLowEndDevice() &&
            sampleBasedOnMarketingToken(marketing_token, 5))
        ) {
          this.clarityService.init({});
        }
        this.algoliaService.init({});
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            if (!event.url.includes('/library')) {
              this.webengageService.init({});
            }
            this.posthogService.track();
          }
        });
      }
    }
  }

  listenToAuthStore() {
    this.authStore.select('auth').subscribe((authData) => {
      if (authData.isAuthenticated) {
        this.userLogin = 'logged in';
        this.verifiedEmail = true;
        this.naukriFlowUser = authData.naukriFlowUser;
        this.userSignup = authData.user?.ps_new_user ? 1 : 0;
        this.registrationUtmSource = authData.registrationUtmSource;
        this.registrationUtmMedium = authData.registrationUtmMedium;
        this.registrationUtmCampaign = authData.registrationUtmCampaign;
        this.sourcePageUrl = authData.sourcePageUrl;
        this.sessionStartPageUrl = authData.sessionStartPageUrl;
      } else {
        if (authData.naukriUnverified) {
          this.userLogin = 'logged in';
          this.verifiedEmail = false;
        } else {
          this.userLogin = 'logged out';
        }
      }
    });
  }

  sendEvent(eventAttributes: AnalyticsEvent) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        (window as any).pushEventsToQueue ||
        ((window as any).gtmEventQueue &&
          (window as any).gtmEventQueue.length > 0)
      ) {
        (window as any).gtmEventQueue.push(eventAttributes);
      } else {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.pushEventToDatalayer(eventAttributes);
          }, 0);
        });
      }
    }
  }
  pushEventToDatalayer(eventAttributes) {
    const eventMap = {
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventLabel: eventAttributes.label,
      eventValue: eventAttributes.value,
      eventData: eventAttributes.data,
      displayMode: this.displayMode,
      userLogin: this.userLogin,
      verifiedEmail: this.verifiedEmail,
      naukriFlowUser: this.naukriFlowUser,
      userSignup: this.userSignup,
      registrationUtmSource: this.registrationUtmSource,
      registrationUtmMedium: this.registrationUtmMedium,
      registrationUtmCampaign: this.registrationUtmCampaign,
      sourcePageUrl: this.sourcePageUrl,
      sessionStartPageUrl: this.sessionStartPageUrl,
      ...eventAttributes.data,
    };
    if (this.environment?.app === 'publicsection') {
      this.pushPublicsectionWebengageEvents(eventAttributes);
      this.pushPublicSectionClarityEvents(eventAttributes);
      this.pushPublicSectionPosthogEvents(eventAttributes);
      dataLayer.push(eventMap);
      return;
    }
    dataLayer.push(eventMap);
  }

  pushPublicsectionWebengageEvents(eventAttributes) {
    this.webengageService.pushWebEngageEvent({
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventData: eventAttributes.data,
    });
  }

  pushPublicSectionClarityEvents(eventAttributes) {
    this.clarityService.pushClarityEvent({
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventData: eventAttributes.data,
    });
  }

  pushPublicSectionPosthogEvents(eventAttributes) {
    this.posthogService.pushPosthogEvent({
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventData: {
        ...eventAttributes.data,
        userLogin: this.userLogin,
      },
    });
  }

  sendEcommerceEvent(
    event: string,
    action: string,
    label: string,
    ecommerce: EcommerceAction
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const sendData = {
        event,
        eventCategory: 'Ecommerce',
        eventAction: action,
        eventLabel: label,
        ecommerce,
      };
      dataLayer.push(sendData);
    }
  }

  sendGA4EcommerceEvent(event_name, ecommerceData) {
    if (isPlatformBrowser(this.platformId)) {
      const sendData = {
        event: event_name,
        ecommerce: ecommerceData,
      };
      if (
        (window as any).pushEventsToQueue ||
        ((window as any).gtmEcommerceQueue &&
          (window as any).gtmEcommerceQueue.length > 0)
      ) {
        (window as any).gtmEcommerceQueue.push({ ecommerce: null });
        (window as any).gtmEcommerceQueue.push(sendData);
      } else {
        dataLayer.push({ ecommerce: null });
        dataLayer.push(sendData);
      }
    }
  }

  getGoogleClickId() {
    if (isPlatformBrowser(this.platformId)) {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const gclid = url.searchParams.get('gclid') || '';
      return gclid;
    } else {
      return '';
    }
  }

  setCustomVariables(customVariablesMap: {}) {
    if (isPlatformBrowser(this.platformId)) {
      if ((window as any).pushEventsToQueue) {
        window.addEventListener(
          'gtm_script_loaded',
          () => {
            dataLayer.push({ ...customVariablesMap });
          },
          { once: true }
        );
      } else {
        dataLayer.push({ ...customVariablesMap });
      }
    }
  }

  clickEvent(name, config: any = {}) {
    this.sendEvent({
      name,
      category: 'click',
      action: config?.action || name,
      label: config?.label,
      data: {
        ...config.data,
      },
    });
  }

  sendAlgoliaEvent(event) {
    this.algoliaService.sendAlgoliaEvent(event);
  }

  setAlgoliaUser(token) {
    this.algoliaService.setAlgoliaUser(token);
  }

  sendPSUserDefined(data) {
    this.webengageService.userDefine(data);
    this.clarityService.userDefine(data);
    this.posthogService.userDefine(data);
  }

  sendGTAGEvents(event, eventKey, eventData) {
    gtag(event, eventKey, eventData);
  }

  webengageLogout() {
    this.webengageService.logout();
  }

  posthogLogout() {
    this.posthogService.logout();
  }

  private setDisplayMode() {
    if (isPlatformBrowser(this.platformId)) {
      this.displayMode = 'browser';
      const mqStandAlone = '(display-mode: standalone)';
      if (window.matchMedia(mqStandAlone).matches) {
        this.displayMode = 'pwa';
      }
    }
  }

  private isLowEndDevice() {
    const logicalProcessors = navigator.hardwareConcurrency || 8;
    const deviceMemory = (navigator as any).deviceMemory || 8;
    return deviceMemory <= 2 || logicalProcessors < 4;
  }
}
