import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { URLS } from '@codingninjas/networking';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { NaukriAnalyticsService } from './naukri-analytics.service';
import { AuthState } from '@codingninjas/auth';
import { State, Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class NaukriReverseAutoLoginService {
  isMobile = false;
  navReader$ = new Subject();
  config;
  externalHref;
  constructor(
    private naukriAnalyticsService: NaukriAnalyticsService,
    @Inject(PLATFORM_ID) private _platformId: object,
    private router: Router,
    private authStore: Store<AuthState>,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.isMobile = !this.deviceDetectorService.isDesktop();

    this.authCode$.subscribe((caller) => {
      let redirectURL = this.config.redirectURL;
      if (this.naukriVendorTokenJourneyId) {
        redirectURL =
          redirectURL +
          `?naukri_vendor_token_journey_id=${this.naukriVendorTokenJourneyId}`;
      }
      let url = this.config.desktopLanding;
      const url_params = {
        code: this.authValue,
        redirectURL,
        state: this.config.state,
        utm_source: this.config.utm_source,
        utm_campaign: this.config.utm_campaign,
        utm_medium: this.config.desktopMedium,
      };
      if (this.isMobile) {
        url = this.config.mobileLanding;
        url_params['utm_medium'] = this.config.mobileMedium;
      }

      const link = this.router.serializeUrl(
        this.router.createUrlTree([url], {
          queryParams: url_params,
        })
      );
      if (isPlatformBrowser(this._platformId)) {
        if (caller === 'classroom') {
          window.location.href = 'https:/' + link;
        } else {
          const dispatch = document.createElement('a');
          dispatch.href = 'https:/' + link;
          dispatch.target = '_blank';
          dispatch.click();
        }

        this.navReader$.next(true);
      }
    });
  }

  client_id =
    '62a81b8979574b4908533d13528a7191c97fc1eeb91e0ee4dbbda154f8a7ab54';
  authValue = null;
  naukriVendorTokenJourneyId = null;
  authCode$ = new Subject();

  initiateRevAutoLogin(
    caller,
    href = null,
    naukriVendorTokenJourneyId = '',
    authorization_code = null
  ) {
    const endpoint =
      'https://api.codingninjas.com/' + URLS.NINJASAUTH.GET_ACCESS_TOKEN;

    if (!!authorization_code) {
      this.externalHref = href;
      this.authValue = authorization_code;
      this.naukriVendorTokenJourneyId = naukriVendorTokenJourneyId;
      this.authCode$.next(caller);
    } else {
      this.naukriAnalyticsService
        .fetchTempTokenNaukri(
          endpoint,
          this.client_id,
          naukriVendorTokenJourneyId
        )
        .subscribe((response) => {
          if (response.isSuccessful()) {
            this.externalHref = href;
            this.authValue = response.data.authorization_code;
            this.naukriVendorTokenJourneyId =
              naukriVendorTokenJourneyId ||
              response.data.naukri_token_journey_id;
            this.authCode$.next(caller);
          }
        });
    }
  }

  initiateRevAutoLoginForPaidUser(caller, path) {
    this.authStore.select('auth').subscribe((authData) => {
      if (authData.user?.naukri_merged_profile) {
        this.initiateRevAutoLogin(caller, path);
      } else {
        window.location.href = path;
      }
    });
  }

  generateNaukriStaticTouchPointLink() {
    const url = `www.naukri.com/jobs-in-india`;
    const url_params = {
      qctopCompany: [195, 283, 247, 81, 249],
      clusters: 'qctopCompany',
      functionAreaIdGid: [3, 5, 8, 10, 15],
      state: 'source=cn',
      source: 'code_studio',
      campaign: 'naukri_jobs_on_code_studio',
      medium: 'desktop',
    };
    if (this.isMobile) {
      url_params['medium'] = 'mobile_site';
    }

    const link = this.router.serializeUrl(
      this.router.createUrlTree([url], {
        queryParams: url_params,
      })
    );

    return 'https:/' + link;
  }

  setConfig(config) {
    this.config = config;
  }
}
