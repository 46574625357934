import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AcceptDoubt,
  AcceptedDoubt,
  ActiveDoubtsFetched,
  AssignAndAcceptDoubt,
  AssignDoubt,
  AssigningDoubt,
  AvailableDoubtsCountFetched,
  AvailableDoubtsFetched,
  ChangeActionStatus,
  DoubtActionTypes,
  DoubtAssigned,
  DoubtAssignError,
  FetchActiveDoubts,
  FetchAvailableDoubts,
  FetchMentorCourseModules,
  FetchPendingOnUserDoubts,
  FetchResolvedDoubts,
  FetchReviewPendingDoubts,
  MentorCourseModulesFetched,
  NoopAction,
  PendingOnUserDoubtsFetched,
  RejectDoubt,
  RejectedDoubt,
  RemoveAssignedDoubt,
  ResolvedDoubtsFetched,
  ReviewPendingDoubtsFetched,
  UnassignDoubt,
  UnassignedDoubt,
  UpdateDoubtActivityStatus,
} from '../actions/doubt.actions';
import { DoubtState } from '../reducers/doubt.reducer';
import { map, skip, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Resource } from '@codingninjas/networking';
import { CoreService } from '../../../core.service';
import { DoubtResponse } from '../models/doubt-response';
import { DoubtsResponse } from '../models/doubts-response';
import { Store } from '@ngrx/store';
import { AssignedDoubtResponse } from '../models/assigned-doubt-response';
import { environment } from '../../../../../environments/environment';
import { CourseModulesResponse } from '../models/course-modules-response';
import { AvailableDoubtsCountResponse } from '../models/available-doubts-count-response';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { fetch } from '@nrwl/angular';

function generateErrorMessage(errorObject) {
  return `${errorObject.message}`;
}

@Injectable()
export class DoubtEffects {
  doubtAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.ASSIGN_DOUBT),
      withLatestFrom(this.store$),
      fetch({
        run: (action: AssignDoubt, state: DoubtState) => {
          return this.apiService.assignDoubt(state.doubt.onlineMode).pipe(
            map((resource: Resource<AssignedDoubtResponse>) => {
              if (resource.isSuccessful()) {
                return new DoubtAssigned(
                  resource.data.doubt,
                  resource.data.timer,
                  resource.data.available_doubts_count,
                  resource.data.timer_complete_action,
                  resource.data.doubt_point
                );
              } else if (resource.isLoading()) {
                return new AssigningDoubt();
              } else {
                return new DoubtAssignError(
                  generateErrorMessage(resource.error)
                );
              }
            })
          );
        },
      })
    )
  );

  activeDoubts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.FETCH_ACTIVE_DOUBTS),
      fetch({
        run: (action: FetchActiveDoubts) => {
          return this.apiService.fetchActiveDoubts().pipe(
            skip(1),
            map((resource: Resource<DoubtsResponse>) => {
              if (resource.isSuccessful()) {
                return new ActiveDoubtsFetched(resource.data.doubts);
              } else {
                return new NoopAction();
              }
            })
          );
        },
      })
    )
  );

  availableDoubts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.FETCH_AVAILABLE_DOUBTS),
      fetch({
        run: (action: FetchAvailableDoubts) => {
          return this.apiService
            .fetchAvailableDoubts(
              action.limit,
              action.offset,
              action.courseModuleId,
              action.createdAtFilter,
              action.selectedDoubtId,
              action.selectedUserId
            )
            .pipe(
              skip(1),
              map((resource: Resource<DoubtsResponse>) => {
                if (resource.isSuccessful()) {
                  return new AvailableDoubtsFetched(
                    resource.data.doubts,
                    resource.data.total_count
                  );
                } else {
                  return new NoopAction();
                }
              })
            );
        },
      })
    )
  );

  availableDoubtsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.FETCH_AVAILABLE_DOUBTS_COUNT),
      withLatestFrom(this.store$),
      switchMap(([action, state]) => {
        return this.apiService
          .fetchAvailableDoubtsCount(state.doubt.onlineMode)
          .pipe(
            map((resource: Resource<AvailableDoubtsCountResponse>) => {
              if (resource.isSuccessful()) {
                return new AvailableDoubtsCountFetched(
                  resource.data.available_doubts_count,
                  resource.data.slot_active
                );
              } else {
                return new NoopAction();
              }
            })
          );
      })
    )
  );

  courseModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.FETCH_MENTOR_COURSE_MODULES),
      fetch({
        run: (action: FetchMentorCourseModules) => {
          return this.apiService.getMentorCourseModules().pipe(
            skip(1),
            map((resource: Resource<CourseModulesResponse>) => {
              if (resource.isSuccessful()) {
                return new MentorCourseModulesFetched(resource.data.modules);
              } else {
                return new NoopAction();
              }
            })
          );
        },
      })
    )
  );

  reviewPendingDoubts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.FETCH_REVIEW_PENDING_DOUBTS),
      fetch({
        run: (action: FetchReviewPendingDoubts) => {
          return this.apiService
            .fetchReviewPendingDoubts(action.limit, action.offset)
            .pipe(
              skip(1),
              map((resource: Resource<DoubtsResponse>) => {
                if (resource.isSuccessful()) {
                  return new ReviewPendingDoubtsFetched(
                    resource.data.doubts,
                    resource.data.total_count
                  );
                } else {
                  return new NoopAction();
                }
              })
            );
        },
      })
    )
  );

  resolvedDoubts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.FETCH_RESOLVED_DOUBTS),
      fetch({
        run: (action: FetchResolvedDoubts) => {
          return this.apiService
            .fetchResolvedDoubts(
              action.limit,
              action.offset,
              action.from,
              action.to
            )
            .pipe(
              skip(1),
              map((resource: Resource<DoubtsResponse>) => {
                if (resource.isSuccessful()) {
                  return new ResolvedDoubtsFetched(
                    resource.data.doubts,
                    resource.data.total_count,
                    action.filter_applied
                  );
                } else {
                  return new NoopAction();
                }
              })
            );
        },
      })
    )
  );

  pendingDoubts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.FETCH_PENDING_ON_USER_DOUBTS),
      fetch({
        run: (action: FetchPendingOnUserDoubts) => {
          return this.apiService
            .fetchPendingOnUserDoubts(action.limit, action.offset)
            .pipe(
              skip(1),
              map((resource: Resource<DoubtsResponse>) => {
                if (resource.isSuccessful()) {
                  return new PendingOnUserDoubtsFetched(
                    resource.data.doubts,
                    resource.data.total_count
                  );
                } else {
                  return new NoopAction();
                }
              })
            );
        },
      })
    )
  );

  acceptDoubt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.ACCEPT_DOUBT),
      withLatestFrom(this.store$),
      fetch({
        run: (action: AcceptDoubt, state: DoubtState) => {
          return this.apiService
            .acceptDoubt(state.doubt.assignment.doubtId)
            .pipe(
              map((resource: Resource<DoubtResponse>) => {
                if (resource.isSuccessful()) {
                  window.open(
                    environment.codezenUrl +
                      'app/mentors/doubts/' +
                      state.doubt.assignment.doubtId.toString()
                  );
                  return new AcceptedDoubt(resource.data.doubt);
                } else if (resource.isLoading()) {
                  return new ChangeActionStatus(true, 'Accepting...', null);
                } else {
                  return new ChangeActionStatus(
                    false,
                    null,
                    generateErrorMessage(resource.error)
                  );
                }
              })
            );
        },
      })
    )
  );

  assignAndAcceptDoubt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.ASSIGN_AND_ACCEPT_DOUBT),
      fetch({
        run: (action: AssignAndAcceptDoubt) => {
          return this.apiService.assignAndAcceptDoubt(action.doubtId).pipe(
            skip(1),
            map((resource: Resource<DoubtResponse>) => {
              if (resource.isSuccessful()) {
                window.open(
                  environment.codezenUrl +
                    'app/mentors/doubts/' +
                    action.doubtId.toString()
                );
                return new AcceptedDoubt(resource.data.doubt);
              } else {
                return new NoopAction();
              }
            })
          );
        },
      })
    )
  );

  unassignDoubt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.UNASSIGN_DOUBT),
      withLatestFrom(this.store$),
      fetch({
        run: (action: UnassignDoubt, state: DoubtState) => {
          return this.apiService
            .unassignDoubt(state.doubt.assignment.doubtId)
            .pipe(
              map((resource: Resource<DoubtResponse>) => {
                if (resource.isSuccessful()) {
                  return new UnassignedDoubt();
                } else if (resource.isLoading()) {
                  return new ChangeActionStatus(true, 'Unassigning...', null);
                } else {
                  return new ChangeActionStatus(
                    false,
                    null,
                    generateErrorMessage(resource.error)
                  );
                }
              })
            );
        },
      })
    )
  );

  rejectDoubt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoubtActionTypes.REJECT_DOUBT),
      withLatestFrom(this.store$),
      fetch({
        run: (action: RejectDoubt, state: DoubtState) => {
          return this.apiService
            .rejectDoubt(state.doubt.assignment.doubtId, action.reason)
            .pipe(
              map((resource: Resource<DoubtResponse>) => {
                if (resource.isSuccessful()) {
                  return new RejectedDoubt();
                } else if (resource.isLoading()) {
                  return new ChangeActionStatus(true, 'Rejecting...', null);
                } else {
                  return new ChangeActionStatus(
                    false,
                    null,
                    generateErrorMessage(resource.error)
                  );
                }
              })
            );
        },
      })
    )
  );

  actionSubjectHandler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DoubtActionTypes.REJECTED_DOUBT,
          DoubtActionTypes.UNASSIGNED_DOUBT,
          DoubtActionTypes.UPDATE_DOUBT_ACTIVITY_STATUS,
          DoubtActionTypes.LOAD_MORE_DOUBT,
          ROUTER_NAVIGATED
        ),
        withLatestFrom(this.store$),
        tap(([action, state]) => {
          const activeDoubtIds = state.doubt.activeDoubtIds;
          const doubtActivityStatus = state.doubt.doubtActivityStatus;
          const assignment = state.doubt.assignment;

          const noActiveDoubts = activeDoubtIds.length === 0;
          const workplaceActive =
            state.router.state.url === '/doubts/workplace';
          const noAssignment = assignment.doubtId < 0;
          const notLoading = !assignment.loading;

          if (!workplaceActive) {
            this.store$.dispatch(new RemoveAssignedDoubt());
          } else {
            if (noActiveDoubts && doubtActivityStatus) {
              if (noAssignment && notLoading) {
                this.store$.dispatch(new AssignDoubt());
              }
            } else {
              this.store$.dispatch(new RemoveAssignedDoubt());
            }
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private apiService: CoreService
  ) {}
}
